/**
 * @type {import('next-translate').I18nConfig} config
 */
module.exports = {
  locales: ['vi', 'en'],
  defaultLocale: 'en',
  localeDetection: false,
  pages: {
    '*': ['common', 'title'],
  },
  extensionsRgx: /\.page\.(tsx|ts|js|mjs|jsx)$/,
  // @ts-ignore
  loadLocaleFrom: (lang, ns) => {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return Promise.resolve(require(`./src/locales/${lang}/${ns}.json`));
  },
  logBuild: true,
};
