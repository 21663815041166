import useLocalStorageSsr from '@/hooks/useLocalStorageSsr';
import { ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

const PRIMARY_COLORS = {
  // DEFAULT
  default: palette.light.primary,
  // PURPLE
  purple: {
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  cyan: {
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  blue: {
    lighter: '#CCDFFF',
    light: '#6697FF',
    main: '#0045FF',
    dark: '#0027B7',
    darker: '#00137A',
    contrastText: '#fff',
  },
  // ORANGE
  orange: {
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  red: {
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
};

export type SettingsContextProps = {
  themeMode: 'light' | 'dark';
  themeColor: keyof typeof PRIMARY_COLORS;
  setColor: {
    lighter: string;
    light: string;
    main: string;
    dark: string;
    darker: string;
    contrastText: string;
  };
  colorOptions: {
    name: string;
    value: string;
  }[];
  onChangeMode: (themeMode: SettingsContextProps['themeMode']) => void;
  onChangeColor: (themeColor: SettingsContextProps['themeColor']) => void;
};

const SettingsContext = createContext<SettingsContextProps | undefined>(undefined);

export function SettingsProvider({ children }: { children: ReactNode }): JSX.Element {
  const [localSettings, setLocalSettings] = useLocalStorageSsr('settings', {
    themeMode: 'light',
    themeColor: 'default',
  } as Pick<SettingsContextProps, 'themeMode' | 'themeColor'>);

  const onChangeMode: SettingsContextProps['onChangeMode'] = useCallback(
    (themeMode) => {
      setLocalSettings({ ...localSettings, themeMode });
    },
    [localSettings, setLocalSettings],
  );

  const onChangeColor: SettingsContextProps['onChangeColor'] = useCallback(
    (themeColor: SettingsContextProps['themeColor']) => {
      setLocalSettings({ ...localSettings, themeColor });
    },
    [localSettings, setLocalSettings],
  );

  const value = useMemo(
    () => ({
      ...localSettings,
      onChangeMode,
      onChangeColor,
      setColor: PRIMARY_COLORS[localSettings.themeColor],
      colorOptions: Object.entries(PRIMARY_COLORS).map(([name, color]) => ({
        name,
        value: color.main,
      })),
    }),
    [localSettings, onChangeColor, onChangeMode],
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}

export function useSettings(): SettingsContextProps {
  const settings = useContext(SettingsContext);
  if (settings === undefined) {
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${useSettings.name} must be used within a ${SettingsContext.displayName} Provider`,
    );
  }

  return settings;
}
