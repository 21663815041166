import { ReactNode, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import type { RouteConfig } from '@/utils/RoutesParser';
import { CollapseDrawerProvider, useCollapseDrawer } from './collapseDrawer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import sidebarConfig from './sidebarConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')(() => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 12,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

function Content({ children }: { children?: ReactNode }) {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  return (
    <MainStyle
      sx={{
        transition: theme.transitions.create('margin', {
          duration: theme.transitions.duration.complex,
        }),
        ...(collapseClick && {
          ml: '102px',
        }),
      }}
    >
      {children}
    </MainStyle>
  );
}

export function MainLayout({ children }: { children?: ReactNode }) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <CollapseDrawerProvider>
        <Navbar onOpenSidebar={() => setOpen(true)} />
        <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

        <Content>{children}</Content>
      </CollapseDrawerProvider>
    </RootStyle>
  );
}

const routeConfig: RouteConfig = {
  pathName: '',
  getLayouts: [(page) => <MainLayout>{page}</MainLayout>],
  children: sidebarConfig.map((item) => {
    if (!item) return;
    return {
      title: item.title,
      pathName: item.key,
      children: item.children?.map((child) => {
        return {
          title: child.title,
          pathName: child.key,
        };
      }),
    };
  }),
};

export default routeConfig;
