import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import RoutesParser, { RouteConfig } from '../utils/RoutesParser';
import mainRouteConfig, { MainLayout } from './main';

const routesConfig: RouteConfig[] = [
  mainRouteConfig,
  { pathName: 'login', onlyUnauthenticated: true, title: 'login' },
];

const routes = new RoutesParser(routesConfig);

export function Routes({ children }: { children: ReactNode }) {
  const router = useRouter();
  const route = routes.getRoute(router.pathname);
  const { t } = useTranslation('title');

  return (
    <>
      <Head>
        <title>
          {route.title
            ? route.title
                .split(': ')
                .map((partialTitle) => t(partialTitle))
                .join(': ')
            : t('default')}
        </title>
      </Head>
      {route.onlyUnauthenticated ? children : <MainLayout>{children}</MainLayout>}
    </>
  );
}
