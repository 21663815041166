import { useAuth } from '@/providers/auth';
import { useRef, useState } from 'react';
// next
import NextLink from 'next/link';
// material
import { alpha } from '@mui/material/styles';
import { Box, Avatar, Button, Divider, MenuItem, Typography, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
// components
import MenuPopover from './MenuPopover';
import { AUTH_PATH } from '../../constants';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  { label: 'Home', Icon: HomeIcon, linkTo: '/' },
  // { label: 'Profile', Icon: PersonIcon, linkTo: '#' },
  // { label: 'Settings', Icon: SettingsIcon, linkTo: '#' },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { logOut } = useAuth();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    void logOut();
  };

  const { currentLearnerProfile, user } = useAuth();
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar
          alt="My Avatar"
          src={currentLearnerProfile?.avatarUrl || '/static/mock-images/avatars/avatar_default.jpg'}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.email}
          </Typography>
          <Typography variant="subtitle1" noWrap>
            {user?.phoneNumber}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentLearnerProfile?.displayName}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <NextLink
            key={option.label}
            href={option.linkTo}
            passHref
            style={{
              textDecoration: 'none',
              color: '#000',
            }}
          >
            <MenuItem onClick={handleClose} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
              <option.Icon
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />
              {option.label}
            </MenuItem>
          </NextLink>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogOut}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
