import { useEffect, Dispatch, SetStateAction, useState } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';

function useLocalStorageSsr<T>(key: string): [T, Dispatch<SetStateAction<T>>, () => void];
function useLocalStorageSsr<T>(
  key: string,
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>, () => void];
function useLocalStorageSsr<T>(
  key: string,
  initialValue?: T | null,
): [T | undefined | null, Dispatch<SetStateAction<T | undefined | null>>, () => void] {
  const [state, setState] = useState(initialValue);
  const [localState, setLocalState, remove] = useLocalStorage(key, state);
  useEffect(() => {
    if (state !== localState) {
      setState(localState);
    }
  }, [localState, state]);

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        let value: string | null = null;
        if (e.newValue) {
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value = JSON.parse(e.newValue);
          } catch {
            // ignore error
          }
        }
        setLocalState(value as T | null);
      }
    };
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, setLocalState]);

  return [state, setLocalState, remove];
}

export default useLocalStorageSsr;
