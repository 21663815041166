import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  TypePolicies,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import env from '@beam-australia/react-env';
import { createClient } from 'graphql-ws';
import { isEqual } from 'lodash-es';
import merge from 'lodash-es/merge';

const isServer = (): boolean => typeof window === 'undefined';

export interface CreateClientOptions {
  uri: string;
  uriV2: string;
  typePolicies?: TypePolicies;
  getHeaders?: () => Promise<Record<string, string>> | Record<string, string>;
}

export function createApolloClient(
  options: CreateClientOptions,
): ApolloClient<NormalizedCacheObject> {
  const headersLink = setContext(async (_, prevContext: Record<string, any>) => {
    return { headers: { ...prevContext.headers, ...(await options.getHeaders?.()) } };
  });

  const wsLink =
    typeof window === 'undefined'
      ? null
      : new GraphQLWsLink(
          createClient({
            url: env('GRAPHQL_WS_API'),
          }),
        );

  const httpLinkV1 = createHttpLink({ uri: options.uri, credentials: 'include' });
  const httpLinkV2 = createHttpLink({ uri: options.uriV2, credentials: 'include' });
  const handlerHttp = split(
    ({ getContext }) => {
      const context = getContext();
      return context.v2 as boolean;
    },
    httpLinkV2,
    httpLinkV1,
  );

  const errorLink = onError((error) => {
    if (error) {
      const firstError = error?.graphQLErrors?.[0]?.message || 'NO-ERROR';
    }
  });

  const link =
    typeof window !== 'undefined' && wsLink !== null
      ? split(
          ({ query }) => {
            const def = getMainDefinition(query);
            return def.kind === 'OperationDefinition' && def.operation === 'subscription';
          },
          wsLink,
          handlerHttp,
        )
      : handlerHttp;

  return new ApolloClient({
    ssrMode: isServer(),
    cache: new InMemoryCache({ typePolicies: options.typePolicies }),
    link: from([errorLink, headersLink, link]),
    connectToDevTools: true,
  });
}

export function mergeApolloCache(
  client: ApolloClient<NormalizedCacheObject>,
  state?: NormalizedCacheObject,
): void {
  if (!state) {
    return;
  }

  const existingCache = client.extract();

  const data = merge(state, existingCache, {
    arrayMerge: (destinationArray: any, sourceArray: any) => [
      ...sourceArray,
      ...destinationArray.filter((d: any) => sourceArray.every((s: any) => !isEqual(d, s))),
    ],
  });

  client.restore(data);
}

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export interface ApolloState {
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
}

export function getApolloState(props: ApolloState): NormalizedCacheObject | undefined {
  return props?.[APOLLO_STATE_PROP_NAME];
}
