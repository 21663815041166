'use client';

import { ApolloProvider } from '@apollo/client';
import { ReactNode, useMemo } from 'react';
import {
  ApolloState,
  createApolloClient,
  CreateClientOptions,
  getApolloState,
  mergeApolloCache,
} from './apollo';

export default function ApolloProviderWrapper({
  children,
  options,
  initialState,
}: {
  children: ReactNode;
  options: CreateClientOptions;
  initialState: ApolloState;
}): JSX.Element {
  const client = useMemo(() => createApolloClient(options), [options]);

  useMemo(() => {
    mergeApolloCache(client, getApolloState(initialState));
  }, [client, initialState]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
