import type { Theme, Components } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Paper(_theme: Theme): Components {
  return {
    MuiPaper: {
      // defaultProps: {
      //   elevation: 0,
      // },

      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  };
}
