export type GetLayout = (page: JSX.Element) => JSX.Element;

export type RouteConfig = {
  pathName?: string;
  title?: string;
  getLayouts?: GetLayout[];
  onlyUnauthenticated?: boolean;
  children?: RouteConfig[];
};

export type RouteObject = {
  title?: string;
  onlyUnauthenticated: boolean;
  getLayout: GetLayout;
};

export default class RoutesParser {
  private static defaultGetLayout = (page: JSX.Element) => page;

  private routes: Record<string, RouteObject>;

  buildRoutes(configs: RouteConfig[], parent?: Omit<RouteConfig, 'children'>) {
    let obj: Record<string, RouteObject> = {};
    configs.forEach((config) => {
      const pathName = [parent?.pathName, config.pathName].filter(Boolean).join('/');
      const getLayouts = [...(parent?.getLayouts || []), ...(config.getLayouts || [])];
      const title = `${parent?.title ? `${parent.title}: ` : ''}${config.title || ''}` || undefined;
      const onlyUnauthenticated =
        config.onlyUnauthenticated || parent?.onlyUnauthenticated || false;

      if (pathName || pathName === '') {
        obj[`/${pathName}`] = {
          getLayout: (page: JSX.Element) =>
            [...getLayouts].reverse().reduce((value, fn) => fn(value), page),
          title,
          onlyUnauthenticated,
        };
      }
      if (config.children) {
        obj = {
          ...obj,
          ...this.buildRoutes(config.children, {
            pathName,
            getLayouts,
            title,
            onlyUnauthenticated,
          }),
        };
      }
    });
    return obj;
  }

  constructor(configs: RouteConfig[]) {
    this.routes = this.buildRoutes(configs);
  }

  getRoute(path: string): RouteObject {
    return (
      this.routes[path] || {
        getLayout: RoutesParser.defaultGetLayout,
        onlyUnauthenticated: false,
      }
    );
  }
}
