import type { Theme, Components } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Container(_theme: Theme): Components {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
  };
}
