import { useMemo, ReactNode } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
// hooks
import { useSettings } from '../providers/settings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

export default function ThemeConfigProvider({ children }: { children: ReactNode }) {
  const { themeMode, setColor } = useSettings();
  // const isLight = themeMode === 'light';
  const isLight = true;

  const themeConfig = useMemo(() => {
    let theme = createTheme({
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    });

    // PrimaryColorTheme
    theme = createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: setColor,
      },
      customShadows: {
        ...theme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    });

    theme.components = componentsOverride(theme);

    return theme;
  }, [isLight, setColor]);

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
