import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { create } from 'zustand';
import shallow from 'zustand/shallow';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  dialogClasses,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useTranslation from 'next-translate/useTranslation';

interface DialogPayload {
  open: boolean;
  handleOk: (() => void) | undefined;
  handleClose: () => void;
  title?: string;
  content?: ReactNode;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  fullWidth?: boolean;
  formId?: string;
}

interface DialogsStore {
  dialogs: Record<string, DialogPayload>;
  addDialog: (key: string, payload: DialogPayload) => void;
  removeDialog: (key: string) => void;
}

const useDialogsStore = create<DialogsStore>((set) => ({
  dialogs: {},
  addDialog: (key, payload) =>
    set((prev) => {
      const dialogs = { ...prev.dialogs };
      dialogs[key] = payload;
      return { dialogs };
    }),
  removeDialog: (key) =>
    set((prev) => {
      const dialogs = { ...prev.dialogs };
      delete dialogs[key];
      return { dialogs };
    }),
}));

export function DialogsContainer() {
  const dialogs = useDialogsStore((state) => state.dialogs);
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <>
      {Object.entries(dialogs).map(
        ([
          key,
          {
            open,
            handleClose,
            handleOk,
            title,
            content,
            cancelText,
            okText,
            loading,
            fullWidth,
            formId,
          },
        ]) => {
          return (
            <Dialog
              key={key}
              fullWidth={fullWidth}
              open={open}
              onClose={handleClose}
              sx={{
                [`& .${dialogClasses.paper}`]: fullWidth
                  ? { margin: theme.spacing(4), maxHeight: `calc(100% - ${theme.spacing(2 * 4)})` }
                  : undefined,
              }}
            >
              {title && <DialogTitle>{title}</DialogTitle>}
              {content && (
                <DialogContent>
                  {typeof content === 'string' ? (
                    <DialogContentText>{content}</DialogContentText>
                  ) : (
                    content
                  )}
                </DialogContent>
              )}

              <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                  {cancelText || t('button.cancel')}
                </Button>
                {(handleOk || formId) && (
                  <LoadingButton
                    variant="contained"
                    type={formId ? 'submit' : 'button'}
                    form={formId}
                    autoFocus
                    loading={loading}
                    onClick={handleOk}
                  >
                    {okText || t('button.ok')}
                  </LoadingButton>
                )}
              </DialogActions>
            </Dialog>
          );
        },
      )}
    </>
  );
}

let ID = -1;

export interface DialogOptions
  extends Pick<
    DialogPayload,
    'title' | 'content' | 'okText' | 'cancelText' | 'fullWidth' | 'formId'
  > {
  defaultOpen?: boolean;
  onOk?: () => Promise<unknown> | void;
  onClose?: () => void;
  closeOnOk?: boolean;
}

export function useDialog(options?: DialogOptions) {
  const [showOptions, setShowOptions] = useState<Omit<DialogOptions, 'defaultOpen'>>();
  const updatedOptions = { ...options, ...showOptions };

  const {
    defaultOpen,
    onOk,
    onClose,
    title,
    content,
    okText,
    cancelText,
    formId,
    fullWidth = !!formId,
    closeOnOk = !formId,
  } = updatedOptions;

  const [open, setOpen] = useState(defaultOpen ?? false);

  const show = useCallback((newOptions?: Omit<DialogOptions, 'defaultOpen'>) => {
    setShowOptions(newOptions);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  }, [onClose]);

  const [loading, setLoading] = useState(false);

  const handleOk = useMemo(() => {
    if (!onOk) {
      return;
    }

    const afterOk = () => {
      if (closeOnOk) {
        handleClose();
      }
    };

    return () => {
      const mayBePromise = onOk();
      if (mayBePromise?.then) {
        setLoading(true);
        return mayBePromise.finally(() => {
          setLoading(false);
          afterOk();
        });
      }
      return afterOk();
    };
  }, [onOk, closeOnOk, handleClose]);

  const key = useMemo(() => {
    ID += 1;
    return `${ID}`;
  }, []);

  const { addDialog, removeDialog } = useDialogsStore(
    (state) => ({
      addDialog: state.addDialog,
      removeDialog: state.removeDialog,
    }),
    shallow,
  );

  useEffect(() => {
    addDialog(key, {
      open,
      handleClose,
      handleOk,
      title,
      content,
      cancelText,
      okText,
      loading,
      fullWidth,
      formId,
    });
  }, [
    addDialog,
    key,
    open,
    handleClose,
    handleOk,
    title,
    content,
    okText,
    cancelText,
    loading,
    fullWidth,
    formId,
  ]);

  // clear dialog when component unmount
  useEffect(() => {
    return () => removeDialog(key);
  }, [key, removeDialog]);

  return { show, close: handleClose, setLoading };
}
