import {
  AuthProviderViewerSessionQueryQuery,
  LearnerProfileFragment,
  UserAdminRole,
  ViewerFragment,
} from '@/__generated__/graphql';
import { createContext } from 'react';

export interface AuthContext {
  user?: ViewerFragment | null;
  role?: UserAdminRole;
  currentLearnerProfile?: LearnerProfileFragment | null;
  learnerProfiles: readonly LearnerProfileFragment[];
  loading: boolean;
  logOut: () => Promise<void>;
  writeQueryViewer: (queryViewer: AuthProviderViewerSessionQueryQuery['viewerSession']) => void;
  reFetchViewer: () => Promise<void>;
}

export default createContext<AuthContext | undefined>(undefined);
