import { ReactNode, useCallback, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
// material
import { useTheme } from '@mui/material/styles';
import { IconButton, GlobalStyles } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1.5),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

export default function NotistackProvider({ children }: { children: ReactNode }): JSX.Element {
  const notistackRef = useRef<SnackbarProvider>(null);

  const action = useCallback((key: string) => {
    return (
      <IconButton size="small" onClick={() => notistackRef.current?.closeSnackbar(key)}>
        <CloseIcon />
      </IconButton>
    );
  }, []);

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        // @ts-ignore
        action={action}
        dense
        maxSnack={1}
        // preventDuplicate
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
